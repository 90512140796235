<template>
  <v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('dialogMessages.itemDeleteConfirmation')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col offset="3" cols="4" sm="4">
        <v-autocomplete
          v-if="clients"
          v-model="clientId"
          :items="selectClients"
          @change="selectClient"
          value="clientId"
          :label="$t('wizard.selectCompany')"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col align-self="center">
        <v-btn
          color="primary"
          dark
          @click="dialog = !dialog"
          :disabled="clientId == null"
        >
          {{ $t('forms.newVehicle') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="vehicles"
          :loading="isLoading"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="editedItem.registration"
                        :label="$t('wizard.registration')"
                        @input="removeWhiteSpace"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="editedItem.vin"
                        label="VIN"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="editedItem.make"
                        :label="$t('wizard.make')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="editedItem.model"
                        :label="$t('wizard.model')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="editedItem.year"
                        :label="$t('wizard.modelYear')"
                        placeholder="2024"
                        clearable
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    {{ $t('wizard.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    {{ $t('wizard.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.birthDate="{ item }">
            <span>
              {{ formatDate(item.birthDate) }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editVehicle(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      isLoading: true,
      dialog: false,
      dialogDelete: false,
      datepicker_show: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        registration: '',
        make: '',
        model: '',
        vin: '',
        year: 0,
      },
      selectClients: [],
      clientId: null,
      headers: [
        {
          text: this.$i18n.t('headers.registration'),
          value: 'registration',
        },
        {
          text: 'VIN',
          value: 'vin',
        },
        {
          text: this.$i18n.t('headers.make'),
          value: 'make',
        },
        {
          text: this.$i18n.t('headers.model'),
          value: 'model',
        },
        {
          text: this.$i18n.t('headers.modelYear'),
          value: 'year',
        },
        {
          text: this.$i18n.t('headers.actions'),
          value: 'actions',
        },
      ],
    }
  },
  methods: {
    selectClient(value) {
      this.$store.dispatch('vehicle/getVehicles', value)
    },
    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('vehicle/deleteVehicle', {
        clientId: this.clientId,
        vehicleId: this.editedIndex,
      })
      this.closeDelete()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editVehicle(item) {
      this.editedIndex = this.vehicles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },

    removeWhiteSpace() {
      this.editedItem.registration = this.editedItem.registration.replace(
        /\s/g,
        ''
      )
    },
    save() {
      const vehicle = Object.assign({}, this.editedItem)
      vehicle.clientId = parseInt(this.clientId)
      vehicle.year = parseInt(vehicle.year)
      if (this.editedIndex > -1) {
        this.$store.dispatch('vehicle/updateVehicle', vehicle)
      } else {
        this.$store.dispatch('vehicle/createVehicle', vehicle)
      }
      this.close()
    },
  },
  mounted() {
    this.$store.dispatch('client/getClients')
  },
  created() {
    this.editedItem = Object.assign({}, this.defaultItem)
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      vehicles: (state) => state.vehicle.vehicles,
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t('forms.newVehicle')
        : this.$i18n.t('forms.editVehicle')
    },
  },
  watch: {
    clients() {
      this.selectClients = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
    vehicles() {
      this.isLoading = false
    },
  },
  destroyed() {
    this.$store.dispatch('vehicle/clearVehicles')
    this.$store.dispatch('client/clearClients')
  },
}
</script>

<style lang="scss" scoped></style>
